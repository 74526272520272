
import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  name: "Home",
  props: {
    id: { type: String, default: "outreach" },
  },
  data() {
    return {
      title: "Unsubscribe Successful",
      showImage: true,
      image: "@/assets/unsubscribe.jpeg",
      message:
        "If you have unsubscribed in error, you can click the button below or click un-subscribe link at the bottom of the original email.",
      loading: false,
      showButton: true,
    };
  },
  methods: {
    async resubscribe() {
      this.loading = false;
      this.showButton = false;
      try {
        this.loading = true;
        this.showImage = false;
        let response = await axios.put("/v1/email/unsubscribe", {
          unsubscribe_id: this.$route.query.id,
          unsubscribe_type: "resubscribe",
        });
        console.log(response);
        if (response.status === 201) {
          this.title = "Re-subscribe successful!";
          this.message =
            "Thank you for keeping informed of crucial messages which could affect your continued health. We look forward to working with you!";
          this.loading = true;
        } else {
          this.$router.push({
            name: "Error",
          });
          console.log("Error");
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: "Error",
        });
      }
    },
  },
  async mounted() {
    if (this.$route.query.id) {
      try {
        let response = await axios.put("/v1/email/unsubscribe", {
          unsubscribe_id: this.$route.query.id,
          unsubscribe_type: "unsubscribe",
        });
        console.log(response);
        if (response.status === 201) {
          this.loading = true;
        } else {
          this.$router.push({
            name: "Error",
          });
          console.log("Error");
        }
        this.loading = true;
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: "Error",
        });
      }
    } else {
      this.$router.push({
        name: "Error",
      });
    }
  },
});
